const header = {
  homepage: "https://app.flashmike.com",
  // title: '{ app }',
};

const about = {
  name: "app.FlashMike",
  role: "App & Software Development",
  description: "Dynamic, scalable, and agile.",
  resume: "https://flashmike.com",
  social: {
    linkedin: "https://www.linkedin.com/in/mike-mijaro-2a35a7239/",
    github: "https://github.com/flashmike",
    // youtube: 'https://www.youtube.com/user/flashwebworks/videos',
  },
};

const projects = [
  {
    name: "My Home",
    image: "https://flashmike.com/contents/apps/myhome.jpg",
    alt: "My Home",
    description: "Real Estate website based on my UX case study.",
    stack: ["React", "Vite", "JSX"],
    //sourceCode: '#',
    livePreview: "https://web.flashmike.com/myHome",
  },
  {
    name: "MaMiZiMi",
    image: "https://flashmike.com/contents/apps/mamizimi.jpg",
    alt: "MaMiZiMi Online Shop",
    description:
      "Amazon clone with test integration, user registration & login using MERN stack.",
    stack: ["React", "MongoDB", "ExpressJS", "NodeJS", "Firebase"],
    //sourceCode: '#',
    livePreview: "https://app.flashmike.com/amazon-clone/",
  },
  {
    name: "Your Drum Shop",
    image: "https://flashmike.com/contents/apps/your-drum-shop.jpg",
    alt: "Your Drum Shop",
    description: "eCommerce product selector functionality test.",
    stack: ["React", "Redux", "MUI"],
    //sourceCode: '#',
    livePreview: "https://flashmike.github.io/your-drum-shop/",
  },
  {
    name: "iLugaw",
    image: "https://flashmike.com/contents/apps/ilugaw.jpg",
    alt: "iLugaw",
    description:
      "Web and mobile app development for an online food ordering system, website built in React.",
    stack: ["React", "UI/UX", "Android"],
    //sourceCode: 'https://github.com/flashmike/react-ilugaw',
    livePreview: "https://flashmike.github.io/react-ilugaw/",
  },
  {
    name: "Booking System",
    //image: './assets/apps/booksys.jpg',
    image: "https://flashmike.com/contents/apps/booksys.jpg",
    alt: "Booking System",
    description: "Online booking system functionality.",
    stack: ["React", "Bootstrap", "UI/UX"],
    //sourceCode: '#',
    livePreview: "https://flashmike.github.io/booking-v2/",
  },
  /* {
    name: 'One Nature',
    image: 'https://flashmike.com/contents/apps/onenature.jpg',
    alt: 'One Nature',
    description:
      'One Nature website development (ver2).',
    stack: ['React', 'Vite', 'JSX'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/onenature',
  }, */
  {
    name: "Movie Library",
    image: "https://flashmike.com/contents/apps/movie-app.jpg",
    alt: "Movie Library",
    description: 'Movie title listing with "search" and "add to favorites"',
    stack: ["React", "Bootstrap", "JSX"],
    //sourceCode: 'https://github.com/flashmike/movie-app',
    //livePreview: 'https://flashmike.github.io/movie-app/',
  },
  {
    name: "My Reactified CV",
    image: "https://flashmike.com/contents/apps/mmcv.jpg",
    alt: "My Reactified CV",
    description: "Reactified CV",
    stack: ["React", "Fontawesome"],
    //sourceCode: '#',
    //livePreview: 'https://app.flashmike.com/cv',
  },
  /* {
    name: 'Triconstruct',
    image: 'https://flashmike.com/contents/apps/triconstruct.jpg',
    alt: 'Triconstruct',
    description:
      'Triconstruct web development.',
    stack: ['React', 'JS'],
    sourceCode: '#',
    livePreview: 'https://triconstruct.com',
  }, */
  // {
  //   name: 'Movie Library',
  //   image: 'https://flashmike.com/contents/apps/movie-app.jpg',
  //   alt: 'Movie Library',
  //   description:
  //     'Movie, series, and game titles library listing with "search" and "add to favorites"',
  //   stack: ['React', 'Bootstrap', 'JSX'],
  //   sourceCode: 'https://github.com/flashmike/movie-app',
  //   livePreview: 'https://flashmike.github.io/movie-app/',
  // },
];

const web = [
  {
    name: "Kultura Trip",
    image: "https://flashmike.com/contents/web/kultura_trip.jpg",
    alt: "Kultura Trip",
    description: "e-Commerce shop - [currently building...]",
    stack: ["HTML5", "Bootstrap"],
    livePreview: "https://gigaflapp.com/KulturaTrip/",
  },
  {
    name: "One Nature",
    image: "https://flashmike.com/contents/apps/onenature.jpg",
    alt: "One Nature",
    description: "One Nature in React",
    stack: ["React", "Vite", "JSX"],
    sourceCode: "#",
    livePreview: "https://app.flashmike.com/onenature",
  },
  {
    name: "Triconstruct",
    image: "https://flashmike.com/contents/apps/triconstruct.jpg",
    alt: "Triconstruct",
    description: "One Nature in React",
    stack: ["React", "JS"],
    sourceCode: "#",
    livePreview: "https://triconstruct.com",
  },
  {
    name: "IKAD Engineering",
    image: "https://flashmike.com/contents/web/ikad.jpg",
    alt: "IKAD Engineering",
    stack: ["HTML5", "Bootstrap", "PHP"],
    livePreview: "https://ikad.com.au/",
  },
  {
    name: "Harvest Tours",
    image: "https://flashmike.com/contents/web/harvesttours.jpg",
    alt: "Harvest Tours",
    stack: ["WIX"],
    livePreview: "https://www.harvesttours.com.au/",
  },
  // {
  //   name: 'One Nature Hotels',
  //   image: 'https://flashmike.com/contents/web/onenature.jpg',
  //   alt: 'One Nature Hotels',
  //   stack: ['HTML5', 'Bootstrap', 'PHP'],
  //   livePreview: 'https://onenaturehotels.com',
  // },
  {
    name: "LD Records",
    image: "https://flashmike.com/contents/web/ldr.jpg",
    alt: "Lonesome Day Records",
    stack: ["Wordpress"],
    livePreview: "https://www.lonesomeday.com/",
  },
];

const motion = [
  {
    name: "SME Souk",
    image: "https://flashmike.com/contents/motion/smesouk.jpg",
    alt: "AE",
    livePreview: "https://youtu.be/SW7icCiQRSU",
  },
  {
    name: "Deal Today",
    image: "https://flashmike.com/contents/motion/deal2day.jpg",
    alt: "Deal Today",
    livePreview: "https://youtu.be/Qs_AxkzU26w",
  },
  {
    name: "iMouse",
    image: "https://flashmike.com/contents/motion/imouse.jpg",
    alt: "iMouse",
    livePreview: "https://youtu.be/y25xbuPuT-M",
  },
  {
    name: "Black Ace",
    image: "https://flashmike.com/contents/motion/blackace.jpg",
    alt: "Black Ace",
    livePreview: "https://youtu.be/9Dkk-w-EjIo",
  },
  {
    name: "Learn to Blog",
    image: "https://flashmike.com/contents/motion/learn2blog.jpg",
    alt: "Learn to Blog",
    livePreview: "https://youtu.be/EdQWvZcREhE",
  },
  {
    name: "Whiteboard",
    image: "https://flashmike.com/contents/motion/whiteboard.jpg",
    alt: "Whiteboard",
    livePreview: "https://youtu.be/mud5jXlj6Ls",
  },
];

const graphic = [
  {
    name: "Logo",
    image: "https://flashmike.com/contents/graphics/logo.jpg",
    alt: "Logo",
    livePreview: "https://flashmike.com/contents/graphics/logos/logo_eod.png",
  },
  {
    name: "Header",
    image: "https://flashmike.com/contents/graphics/header.jpg",
    alt: "Header",
    livePreview: "https://flashmike.com/graphics.html",
  },
  {
    name: "Banner",
    image: "https://flashmike.com/contents/graphics/banner.gif",
    alt: "300x250 Banner",
    livePreview:
      "https://flashmike.com/contents/graphics/banners/300x250/6pack_300x250a.gif",
  },
  {
    name: "Branding",
    image: "https://flashmike.com/contents/graphics/branding.jpg",
    alt: "Booking System",
    livePreview:
      "https://flashmike.com/contents/graphics/branding/brand2_2bros.jpg",
  },
  {
    name: "Layout",
    image: "https://flashmike.com/contents/graphics/layout.jpg",
    alt: "300x250 Banner",
    livePreview: "https://flashmike.com/graphics.html",
  },
  {
    name: "Print",
    image: "https://flashmike.com/contents/graphics/print.jpg",
    alt: "Print",
    livePreview: "https://flashmike.com/graphics.html",
  },
];

const skills = [
  // 'HTML',
  // 'CSS',
  // 'JavaScript',
  // 'TypeScript',
  // 'React',
  // 'Redux',
  // 'SASS',
  // 'Material UI',
  // 'Git',
  // 'CI/CD',
  // 'Jest',
  // 'Enzyme',
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: "mike@flashmike.com",
};

export { about, contact, graphic, header, motion, projects, skills, web };
