import uniqid from 'uniqid'
import { projects } from '../../portfolio'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'
import AppsIcon from '@mui/icons-material/Apps';

const Projects = () => {
  if (!projects.length) return null

  return (
    <section id='projects' className='section projects'>
      <h3 className='section__title'><AppsIcon /> App Projects</h3>

      <div className='projects__grid'>
        {projects.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
      </div>

      {/* <div className='btn-container'>
        <a href='https://flashmike.com/web.html' aria-label='source code' target='_blank' rel='noopener noreferrer'>
          <span type='button' className='btn btn--outline'>
            VIEW MORE
          </span>
        </a>
      </div> */}

    </section>
  )
}

export default Projects
