import './Footer.css'

const getCurrentYear = () => {
  return new Date().getFullYear();  };

const Footer = () => (
  <footer className='footer credits'>
    &copy; {getCurrentYear()} app.<a href='https://flashmike.com' className='footer__link credits'>flashmike.com</a> | All Rights Reserved. | <a href='mailto:mike@flashmike.com'>CONTACT</a>
  </footer>
)

export default Footer
